<template>
  <div class="main">
    <ul>
      <a href="https://t.me/bscbnbl" target="_blank">
      <li>
        <svg
          t="1702978886518"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="17731"
          width="24"
          height="24"
        >
          <path
            d="M881.706667 158.592l-756.48 291.712c-51.626667 20.736-51.328 49.536-9.472 62.378667l194.218666 60.586666 449.365334-283.52c21.248-12.928 40.661333-5.973333 24.704 8.192l-364.074667 328.576h-0.085333l0.085333 0.042667-13.397333 200.192c19.626667 0 28.288-9.002667 39.296-19.626667l94.336-91.733333 196.224 144.938667c36.181333 19.925333 62.165333 9.685333 71.168-33.493334l128.810666-607.061333c13.184-52.864-20.181333-76.8-54.698666-61.184z"
            p-id="17732"
            fill="#ffffff"
          ></path>
        </svg>
      </li>
    </a>
    <a href="https://twitter.com/BNBL_6" target="_blank">
      <li>
        <svg
          t="1702978931712"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="18789"
          width="24"
          height="24"
        >
          <path
            d="M1024 194.56a420.352 420.352 0 0 1-120.832 33.28 210.432 210.432 0 0 0 92.16-116.224 422.4 422.4 0 0 1-133.632 51.2A209.92 209.92 0 0 0 499.2 307.2a211.968 211.968 0 0 0 5.632 47.616 596.48 596.48 0 0 1-433.152-220.16 208.896 208.896 0 0 0-28.672 105.472A204.8 204.8 0 0 0 132.096 414.72C97.28 413.696 32.256 404.48 32.256 388.608v2.56a214.528 214.528 0 0 0 173.056 204.8 193.024 193.024 0 0 1-51.2 7.168 199.68 199.68 0 0 1-38.4-3.584 210.944 210.944 0 0 0 196.608 145.92A420.864 420.864 0 0 1 51.2 836.608a438.784 438.784 0 0 1-51.2-3.072 594.944 594.944 0 0 0 322.048 94.208A593.408 593.408 0 0 0 921.6 330.24v-27.136a427.008 427.008 0 0 0 102.4-108.544z m0 0"
            fill="#ffffff"
            p-id="18790"
          ></path>
        </svg>
      </li>
    </a>
    </ul>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.main {
  position: fixed;
  right: 30px;
  bottom: 30px;
  li {
    margin-top: 15px;
  }
}
</style>
