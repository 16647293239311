<template>
  <router-view />
</template>

<script setup>
import { linkWallet } from "@/utils/wallet";
import { onMounted } from "vue";
onMounted(() => {
  linkWallet();
});
</script>
