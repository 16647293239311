import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/layout/layout.vue";

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        name: "home",
        path: "/home",
        component: () => import("@/views/home/HomePage.vue"),
      },
      {
        name: "token",
        path: "/token",
        component: () => import("@/views/token/TokenPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
