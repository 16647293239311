import { createStore } from "vuex";

export default createStore({
  state: {
    isMobile: navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ),
    userAddress: "",
  },
  getters: {},
  mutations: {
    setUserAddress(state, userAddress) {
      state.userAddress = userAddress;
    },
  },
  actions: {},
  modules: {},
});
